@import '_variables.scss';
@import '_mixins.scss';

@keyframes spinY {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.7);
  }
}

@keyframes bounceUpDown {
  0% {
    top: 0;
  }
  50% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}

@keyframes slideDownAnim {
  0% {
    display: none;
  }
  1% {
    opacity: 0;
    transform: scaleY(0);
  }
  99% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    display: block;
  }
}
