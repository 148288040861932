@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/grid/columns.sass';
@import '~bulma/sass/elements/button.sass';

.deleteEventModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index:100000;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
}

.deleteEventModalContent {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $shark;
    width: 100%;
    max-width: 450px;
    height: 150px;
    border-radius: 6px;
}

.deleteEventModalBody {
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.button {
    width:49%;
    height: 50px;
    border-radius: 6px;
    color: $white;
    border: none;
    &:hover {
        color: $white;
        border: 3px solid #7AF286;
    }
}

.yes {
    background: $shark
}

.no {
    background: $red
}

.deleteEventModalButtonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: 450px;
}

.checkboxes{
    margin-left:20%;
    margin-top:2%
}