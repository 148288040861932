@import '../../styles/_variables.scss';
@import '../../styles/_animations.scss';
@import '../../styles/_mixins.scss';
@import '~bulma/sass/utilities/_all.sass';

.searchbar {
  margin-left: $gap-xs;
  margin-right: $gap-xs;
  display: flex;
  align-items: center !important;
  border-radius: $radius;
  max-width: 300px;
  transition: width 0.4s;

  button {
    display: none;
    position: absolute;
    right: $gap-xxs;
    transition: all $duration-short !important;
    font-size: 0.75rem;
  }

  svg {
    position: absolute;
    left: 4px;
  }

  &.large {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: $gap-md;
    width: 100%;
    max-width: 100%;

    button {
      font-size: 20px;
    }
  } // end .large

  &Input {
    width: 100%;
    height: 40px !important;
    border-radius: $radius !important;
    border: none !important;
    box-shadow: 0.3px 0.3px 4px rgba(0, 0, 0, 0.1),
      -0.3px -0.3px 4px rgba(0, 0, 0, 0.1) !important;
    &:focus {
      outline: none;
      box-shadow: 0.3px 0.3px 4px rgba(0, 0, 0, 0.6),
        -0.3px -0.3px 4px rgba(0, 0, 0, 0.6) !important;
    }
  } // end -input
  &InputLarge {
    padding-left: 50px !important;
    font-size: 24px !important;

    @include tablet {
      height: 60px !important;
      font-size: 32px !important;
    }
  }

  &InputSmall {
    padding-left: $gap-xxs !important;
  }
  @include tablet {
    margin-left: $gap-md;
    width: 350px;
    button {
      display: inline-block;
    }
  }

  @include desktop {
    width: 400px;
  }
}
