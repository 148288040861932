@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/grid/columns.sass';

a.listCard {
  display: flex;
  flex-direction: row;
  margin-top: $gap-md * 2 !important;
  border-radius: $radius-small;
  width: 100%;
  height: 70px;
  overflow: hidden;
  color: inherit;
  @include is-clickable;
  @include hover-shadow;

  &:hover {
    color: initial;
  }

  .title {
    white-space: normal;
  }

  @include mobile-xs-to-tablet {
    height: 100px;
  }

  @include tablet {
    height: 194px;
  }

  .event_image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: $radius-small;

    @include mobile-xs-to-tablet {
      width: 100px;
      height: 100px;
    }

    @include tablet {
      width: 194px;
      height: 194px;
      margin-top: 0 !important;
    }
  }

  .event_details {
    position: relative;
    margin: {
      left: $gap-xxs;
    }

    @include tablet {
      margin: {
        left: $gap;
        right: $gap-sm;
        top: 0;
      }
      padding-bottom: 0;
    }
  }

  .space_letters {
    letter-spacing: 2px;
  }

  .descriptionUnderline {
    position: absolute;
    bottom: -$gap-md;
    @include content-divider();
  }

  .community {
    color: $chalice;
  }
} //end list-card

a.gridCard {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  min-height: 401px;
  border-radius: $radius-small;
  margin: 1%;

  color: inherit;
  @include is-clickable;
  @include hover-shadow;

  &:hover {
    color: initial;
  }
  .event_image {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: $radius-small;
    @include mx-auto;
  }

  .event_details {
    padding: $gap-sm $gap-xxs;
    max-width: 100%;
  }

  .space_letters {
    letter-spacing: 2px;
  }

  .eventDescription {
    display: none;
  }

  .descriptionUnderline {
    display: none;
  }

  .community {
    color: $chalice;
  }
}

// ===== Bulma Overrides: Columns =====
.is-multiline {
}

.is-narrow {
}

.column,
.columns {
  padding: 0;
  margin: {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.columns:last-child {
  margin-bottom: initial;
}

.columns:not(:last-child) {
  margin-bottom: initial;
}

.title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  max-width: 100%;
  word-wrap: break-word;
}

.tileTitle {
  max-height: 60px;
}

.descriptionFade {
  @include mobile-xs-up {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    background: rgba(255, 0, 0, 0);
    background: linear-gradient(180deg, rgba(255, 0, 0, 0) 20%, #ffffff 100%);
  }
}
