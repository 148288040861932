@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';
@import '~bulma/sass/utilities/_all.sass';

.eventView {
  .eventImage {
    width: 20rem;
    height: auto;
    object-fit: cover;
    align-self: center;

    @include tablet {
      align-self: stretch;
    }
  }

  .padContent {
    padding: $gap-xxs;
    @include tablet {
      padding: 0;
    }
  }

  .padDivContent {
    padding: $gap-xxs;

    .titleH1 {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      hyphens: auto;
    }
  }

  .banner {
    object-fit: cover;
    max-height: 125px;
    width: 100%;

    @include tablet {
      max-height: 250px;
    }
  }

  .top_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $shark;
    color: white;

    h1 {
      margin-bottom: $gap-xs;
    }

    @include tablet {
      flex-direction: row;
      h1 {
        margin-bottom: $gap;
      }
    }

    .space_letters {
      letter-spacing: 2px;
    }

    .date_display {
      margin-bottom: $gap-xs;
    }

    .series{
      margin-top:20%;
      color:gold;
      font-size:1.3rem
    }

    .panel_right {
      display: flex;
      justify-content: center;
      padding-top: $gap-sm;
      padding-bottom: $gap-xxs;
      & > div {
        margin-right: $gap-sm;
        width: max-content;
      }

      @include tablet {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 0;
        padding-top: $gap-sm;

        & > div {
          margin-bottom: $gap-sm;
          width: 180px;
        }
      }
    }
  }

  .middle_div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @include tablet {
      flex-direction: row;
      padding: {
        top: $gap-sm;
        bottom: $gap-xxs;
        left: 0;
        right: 0;
      }
      div:nth-of-type(1) {
        width: 100%;
      }
    }

    .horizontalBar {
      margin: 0;
    }
  }

  .descriptionDiv {
    margin-bottom: $gap-md;
    button {
      margin-top: $gap-md;
    }

    p:nth-of-type(2) {
      margin-top: $gap-sm;
    }

    @include tablet {
      margin-top: $gap-sm;
      p:nth-of-type(2) {
        margin-top: 0;
        padding: {
          top: $gap;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }

      button {
        margin-top: $gap;
      }
    }
  }

  .descriptionText {
    padding-bottom: 0;
    width: 100%;
    word-wrap: break-word;
    hyphens: auto;
  }

  .socialOptions {
    p {
      margin-bottom: $gap-xs;
      margin-right: $gap-sm;
    }
    button ~ div {
      margin-top: $gap-lg;
      div {
        padding: $gap 0;
      }
    }
    @include tablet {
      margin-top: 0;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .userImage {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px;

    border-radius: 50%;
    background: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
