@import '_variables.scss';
@import '_mixins.scss';

body {
  padding-top: $navbar-height;
  @include Poppins;
}

a {
  color: #000;
  &:hover {
    color: #000;
  }
}

/* hand cursor on clickable elements */
label,
input[type='button'],
input[type='submit'],
input[type='file'],
button {
  cursor: pointer;
}
