@import './variables';
// Import Google Font
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

@font-face {
  font-family: Butler;
  src: url(../assets/fonts/Butler-Bold.woff2) format('woff2'),
    url(../assets/fonts/Butler-Bold.woff) format('woff');
  font-display: block;
}
